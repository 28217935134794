import VueRouter from 'vue-router'
import StaffCardPage from '../pages/StaffCardPage.vue'

const routes = [
  { path: '/:id/:name', name: 'ICS Staff Card', component: StaffCardPage, meta:{
    title: "ICS Staff Card"
  }},
]
const router = new VueRouter({
  routes, // short for `routes: routes`
  mode:'history'
})

router.beforeEach((to, from, next) => {
  document.title = to.params.name ? `${to.params.name} - ICS Staff Card` : to.meta.title
  next()
 })

export default router