<template>
  <v-row class="align-center">
    <v-col cols="4" class="text-left p-0">
      <v-sheet class="pa-2 ma-2 d-flex align-center">
        <v-icon class="mr-2" left>{{ icon }}</v-icon> {{ label }}
      </v-sheet>
    </v-col>
    <v-col cols="8" class="text-left p-0">
      <v-sheet class="pa-2 ma-2">
        <a class="text-decoration-none" v-if="isLink" :href="linkHref">{{ content }}</a>
        <a class="text-decoration-none" v-else-if="isEmailLink" :href="emailHref">{{ content }}</a>
        <a class="text-decoration-none" v-else-if="isTelLink" :href="telHref">{{ content }}</a>
        <span v-else>{{ content }}</span>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    },
    isLink: {
      type: Boolean,
      default: false
    },
    isEmailLink: {
      type: Boolean,
      default: false
    },
    isTelLink: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    emailHref() {
      return this.isEmailLink ? `mailto:${this.content}` : this.content;
    },
    linkHref() {
      return this.isLink ? `${this.content}` : this.content;
    },
    telHref() {
      var tel = `${this.content}`.replace(/\s+/g, '');
      return this.isTelLink ? `tel:${tel}` : tel;
    }
  }
}
</script>