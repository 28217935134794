import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './plugins/router.js'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(VueMeta)
Vue.use(VueRouter);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuetify)

Vue.config.productionTip = false

new Vue({
  vuetify: new Vuetify({
    theme: {
      themes: {
        light: {
          anchor: '#000000',
        },
      },
    },
  }),
  render: h => h(App),
  router
}).$mount('#app')
