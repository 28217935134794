<template>
  <v-row class="align-start">
    <v-col cols="4" class="text-left p-0">
      <v-sheet class="pa-2 ma-2 d-flex align-center">
        <v-icon class="mr-2" left>{{ icon }}</v-icon> {{ label }}
      </v-sheet>
    </v-col>
    <v-col cols="8" class="text-left p-0">
      <v-sheet class="pa-2 ma-2">
        <div v-for="(item) in content">
          <div class="mb-4" v-for="(v, k) in item"><span><b>{{ k }} Office</b></span><br/><span>{{ v }}</span></div>
        </div>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    content: {
      type: Object,
      required: true
    }
  }
}
</script>